import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, PageParams } from '../../interfaces'
import { OrderComment, Filters } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'orderComments',
  stateFactory: true,
  namespaced: true
})
export default class OrderCommentsModule extends VuexModule {
  orderCommentsValue: ResponseData<OrderComment> = defaultData

  orderCommentValue: OrderComment = {
    userId: null,
    text: ''
  }

  filtersValue: Filters = {}

  // ? ------ getters ------ //

  get filters (): Filters {
    return this.filtersValue
  }

  get orderComments (): ResponseData<OrderComment> {
    return this.orderCommentsValue
  }

  get orderComment (): OrderComment {
    return this.orderCommentValue
  }

  /**
     * * Получить comment по id
     */
  get getOrderCommentById () {
    const orderComments = this.orderComments
    return function (id: number): OrderComment | undefined {
      return orderComments.data.find(orderComment => orderComment.id === id)
    }
  }

  /**
     * * Получить comment по id
     */
  get getOrderCommentsByChannelId () {
    const orderComments = this.orderComments
    return function (channelId: number | undefined): OrderComment[] {
      return orderComments.data.filter(orderComment => orderComment.channelId === channelId)
    }
  }

  // ? ------ setters ------ //

    @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

    @Mutation
    resetFilters () {
      this.filtersValue = {}
    }

    @Mutation
    setOrderComments (orderComments: ResponseData<OrderComment>) {
      this.orderCommentsValue = orderComments
    }

    @Mutation
    setOrderComment (orderComment: OrderComment) {
      this.orderCommentValue = orderComment
    }

    @Mutation
    resetOrderComment () {
      this.orderCommentValue = {
        userId: null,
        text: ''
      }
    }

    @Mutation
    resetOrderComments () {
      this.orderCommentsValue = defaultData
    }

    // ? ------ actions ------ //

    @Action({
      rawError: true,
      commit: 'setOrderComments'
    })
    async getOrderComments (params: PageParams): Promise<ResponseData<OrderComment>> {
      try {
        const { data } = await $axios.get(`/shop/orders/${this.filters.orderId}/comments`, { params: { ...params, ...this.filters } })
        const response: ResponseData<OrderComment> = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

    @Action({
      rawError: true
    })
    async createOrderComment (): Promise<OrderComment> {
      try {
        const { data: { data } } = await $axios.post(`/shop/orders/${this.filters.orderId}/comments`, this.orderComment)
        const response: OrderComment = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

    @Action({
      rawError: true
    })
    async editOrderComment (): Promise<OrderComment> {
      try {
        const { data: { data } } = await $axios.put(`/shop/orders/${this.filters.orderId}/comments/${this.orderComment.id}`, this.orderComment)
        const response: OrderComment = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

  /**
   * * Запрос на удаление комментария
   * @param orderId id заказа
   * @param orderCommentId id комментария
   * @returns комментарий
   */
  @Action({
    rawError: true
  })
    async removeOrderComment (ids: { orderId: number, orderCommentId: number }): Promise<OrderComment> {
      try {
        const { data: { data } } = await $axios.delete(`/shop/orders/${ids.orderId}/comments/${ids.orderCommentId}`)
        const response: OrderComment = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }
  //
  // /**
  //  * * Выгрузить в excel
  //  */
  // @Action({
  //   rawError: true
  // })
  // async exportExcel (orderId: number) {
  //   try {
  //     const { data } = await $axios.get(`/shop/orders/${orderId}/Comments/excel`, {
  //       responseType: 'blob'
  //     })
  //     return data
  //   } catch (error) {
  //     throw new FormError(error as AxiosError<FormError>)
  //   }
  // }
}
