import { Module, Mutation } from 'vuex-module-decorators'
import { ChatChannel } from './interfaces'
import { ModuleConfig } from '~/types/store/store-base-type'
import BaseModule from '~/types/store/store-base'

@Module({
  name: 'chatChannels',
  stateFactory: true,
  namespaced: true
})
export default class ChatChannelsModule extends BaseModule<ChatChannel, 'id', keyof ChatChannel> {
  protected config: ModuleConfig<ChatChannel, 'id'> = {
    apiUrl: '/chat-channel',
    identifier: 'id'
  }

  protected itemData: ChatChannel = {
    name: '',
    code: '',
    description: '',
    module: 'orders',
    active: true,
    params: {}
  }

  @Mutation
  resetItem (): void {
    this.itemData = {
      name: '',
      code: '',
      description: '',
      module: 'orders',
      active: true,
      params: {}
    }
  }
}
