import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class OrderCommentsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$orderComments.resetOrderComment()
    this.$orderComments.resetOrderComments()
    this.$orderComments.resetFilters()
    next()
  }

  /**
     * * Получение списка комментов в заказа
     * @param params - параметры пагинации
     * @returns список комментов в заказе
     */
  async getOrderComments (params?: PageParams) {
    try {
      this.$wait.start('getOrderComments')
      this.$orderComments.filters = {
        ...this.$orderComments.filters,
        orderId: this.$orders.currentOrder.id
      }
      return await this.$orderComments.getOrderComments({ ...params, sort: 'createdAt', order: 'desc' })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOrderComments')
    }
  }

  /**
     * * Добавление коммента в заказ
     * @returns коммент
     */
  async createOrderComment () {
    try {
      this.$wait.start('createOrderComment')
      this.$orderComments.orderComment = {
        ...this.$orderComments.orderComment,
        userId: this.$auth.profile.id,
        channelId: this.$chatChannels.item.id,
        text: this.$orderComments.orderComment.text.trim()
      }
      return await this.$orderComments.createOrderComment()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createOrderComment')
    }
  }

  /**
     * * Изменение коммента в заказе
     * @returns коммент
     */
  async editOrderComment () {
    try {
      this.$wait.start('editOrderComment')
      // this.$orderComments.orderComment = {
      //   ...this.$orderComments.orderComment,
      //   userId: this.$auth.profile.id
      // }
      return await this.$orderComments.editOrderComment()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editOrderComment')
    }
  }

  /**
     * * Удаление товара из заказа
     * @param ids - id предложения товара и id заказа
     * @returns предложение товара
     */
  async removeOrderComment (ids: { orderId: number, orderCommentId: number}) {
    try {
      this.$wait.start('removeOrderComment')
      const data = await this.$orderComments.removeOrderComment(ids)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Комментарий удалён'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeOrderComment')
    }
  }
  //
  // /**
  //    * * Выгрузить в excel
  //    */
  // async exportExcelOrderComments (orderId: number) {
  //   try {
  //     this.$wait.start('exportExcel')
  //     return await this.$orderComments.exportExcel(orderId)
  //   } catch (e: any) {
  //     console.error(e)
  //     this.$notify({
  //       type: 'error',
  //       title: e.error_code,
  //       message: e.error_message
  //     })
  //     throw e
  //   } finally {
  //     this.$wait.end('exportExcel')
  //   }
  // }
}
