import { ChatChannel } from './interfaces'
import ChatChannelsModule from './store'
import storePluginApi from '~/types/store/store-plugin-api'

export default function getPluginApi (store: ChatChannelsModule) {
  const api: any = storePluginApi<
      ChatChannel,
      'id',
      keyof ChatChannel
    >(store as any)

  return api
}
