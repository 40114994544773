import { Component } from 'nuxt-property-decorator'
import { ChatChannel, ChatChannelsPageParams } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'

@Component({
  name: 'ChatChannelsMixin'
})
export default class ChatChannelsMixin extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.statesReset('$chatChannels')
    next()
  }

  created () {
    this.$wait.end('leaveRouter')
  }

  async getChatChannelsList (pageParams?: ChatChannelsPageParams<ChatChannel>, save = true) {
    return await this.asyncRequestHandler(
      'getChatChannelsList',
      this.$chatChannels.getList,
      pageParams,
      save
    )
  }

  async getChatChannelsItem (identifier: ChatChannel['id']) {
    return await this.asyncRequestHandler(
      'getChatChannelsItem',
      this.$chatChannels.getItem,
      identifier
    )
  }

  async createChatChannelsItem () {
    await this.asyncRequestHandler(
      'createChatChannelsItem',
      this.$chatChannels.createItem
    )
    this.$notify({
      type: 'success',
      title: 'Выполнено',
      message: 'Канал создан'
    })
  }

  async editChatChannelsItem (identifier: ChatChannel['id']) {
    await this.asyncRequestHandler(
      'editChatChannelsItem',
      this.$chatChannels.editItem,
      identifier
    )

    this.$notify({
      type: 'success',
      title: 'Выполнено',
      message: 'Канал изменен'
    })
  }

  async removeChatChannelsItem (identifier: ChatChannel['id']) {
    await this.asyncRequestHandler(
      'removeChatChannelsItem',
      this.$chatChannels.removeItem,
      identifier
    )

    this.$notify({
      type: 'success',
      title: 'Выполнено',
      message: 'Канал удален'
    })
  }
}
